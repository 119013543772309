import React from 'react';
import { Redirect, useHistory, useParams } from 'react-router-dom';

import BackButton from 'client/components/BackButton';
import ComplianceContainer from 'client/components/ComplianceContainer';
import Page from 'client/components/Page';
import { I9TaskTrackingEvents } from './I9.tracking';
import { LegacyI9PageContent } from 'client/pages/i9/components/LegacyI9PageContent';
import { LoadingPage } from 'client/components/Loading';
import {
    RequiresAdditionalDocumentsPageContent,
    RequiresCjoPageContent,
    RequiresProfilePageContent,
} from 'client/components/RequiresTaskPageContent/RequiresTaskPageContent';
import { WorkBrightI9Content } from './WorkBrightI9/WorkBrightI9Content';
import { datadogRum } from '@datadog/browser-rum';
import { indexLocation } from 'client/utils/redirect';
import { isI9Task } from 'client/utils/task';
import { useTaskDisabledReason } from 'client/hooks/useTaskDisabledReason';
import { useTasks } from 'client/contexts/TasksContext';

type SlugParam = {
    slug?: string;
};

const I9Page = (): JSX.Element => {
    const { slug } = useParams<SlugParam>();
    const { completed, loading, tasks } = useTasks();
    const history = useHistory();
    const task = tasks.find((t) => t.slug === slug);
    const disabledReason = useTaskDisabledReason(task);

    const content = (): JSX.Element => {
        if (disabledReason === 'INCOMPLETE_PROFILE') {
            return (
                <RequiresProfilePageContent heading="Form I-9" task={task!} taskName="form I-9" />
            );
        }

        if (disabledReason === 'INCOMPLETE_CJO') {
            return <RequiresCjoPageContent heading="Form I-9" task={task!} taskName="form I-9" />;
        }

        if (disabledReason === 'INCOMPLETE_DOCUMENTS') {
            return (
                <RequiresAdditionalDocumentsPageContent
                    heading="Form I-9"
                    task={task!}
                    taskName="form I-9"
                />
            );
        }

        if (task!.metadata.formI9.provider !== 'WORKBRIGHT') {
            return <LegacyI9PageContent task={task!} />;
        }

        return (
            <WorkBrightI9Content
                status={task!.status}
                slug={task!.slug}
                rejectionReason={task!.metadata.formI9.resolutionComment}
                externalStatus={task!.externalStatus}
            />
        );
    };

    if (!completed || loading) {
        return <LoadingPage />;
    }

    if (!isI9Task(task)) {
        return <Redirect to={indexLocation} />;
    }

    const previous = (): void => {
        datadogRum.addAction(I9TaskTrackingEvents.backBtnClicked);
        if (history.location.pathname.includes('authorized-rep')) {
            history.push(indexLocation);
        } else {
            history.goBack();
        }
    };

    return (
        <Page title="Form I-9">
            <ComplianceContainer>
                <BackButton onClick={previous} />
                {content()}
            </ComplianceContainer>
        </Page>
    );
};

export default I9Page;
