import BackButton from 'client/components/BackButton';
import ComplianceContainer from 'client/components/ComplianceContainer';
import Page from 'client/components/Page';
import PageContent from './components/PageContent/PageContent';
import React from 'react';
import { LoadingPage } from 'client/components/Loading';
import { Redirect, useHistory, useParams } from 'react-router-dom';
import { RightToWorkTaskTrackingEvents } from './RightToWork.tracking';
import { TaskStatuses, isRightToWorkTask } from 'client/utils/task';
import { datadogRum } from '@datadog/browser-rum';
import { getNodes } from 'client/utils/graphql';
import { indexLocation } from 'client/utils/redirect';
import { useGetUserRtwTaskQuery } from 'client/hooks/graphql';
import { useWorker } from 'client/contexts/WorkerContext';

type SlugParam = {
    slug?: string;
};

const RightToWorkPage = (): JSX.Element => {
    const { slug } = useParams<SlugParam>();

    const { worker, loading: workerLoading, completed: workerCompleted } = useWorker();

    const { data, loading } = useGetUserRtwTaskQuery({
        variables: {
            email: worker?.email,
        },
    });

    const history = useHistory();

    if (loading || workerLoading || !workerCompleted) {
        return <LoadingPage />;
    }

    const rtwTasks: ComplianceTask[] = getNodes<GetUserRtwTaskQuery | undefined>(data);

    const currentTask = rtwTasks.find((t) => t.slug === slug);

    if (!slug || !currentTask || !isRightToWorkTask(currentTask)) {
        return <Redirect to={indexLocation} />;
    }

    // can be null, so we need to check for false explicitly
    const displayTypeFormLink =
        currentTask.metadata.rightToWork.ukCitizen === false &&
        !currentTask.completedDate &&
        currentTask.status === TaskStatuses.NOT_COMPLETED;

    const previous = (): void => {
        datadogRum.addAction(RightToWorkTaskTrackingEvents.backBtnClicked);
        history.goBack();
    };
    return (
        <Page title="Right to Work">
            <ComplianceContainer>
                <BackButton onClick={previous} />
                <PageContent task={currentTask} displayTypeFormLink={displayTypeFormLink} />
            </ComplianceContainer>
        </Page>
    );
};

export default RightToWorkPage;
