import React, { useEffect, useRef, useState } from 'react';
import WorkBrightI9Complete from './WorkBrightI9Complete/WorkBrightI9Complete';
import WorkBrightI9ErrorPage from './WorkBrightI9ErrorPage/WorkBrightI9ErrorPage';
import WorkBrightI9NotComplete from './WorkBrightI9NotComplete/WorkBrightI9NotComplete';
import WorkBrightI9Rejected from './WorkBrightI9Rejected/WorkBrightI9Rejected';
import WorkBrightI9Reverify from './WorkBrightI9Reverify/WorkBrightI9Reverify';
import { Alert, Box, Button, Card, Flex, Heading } from '@indeed/ifl-components';

import Image from 'client/components/Image';
import UpdateWorkerModal from 'client/pages/i9/WorkBrightI9/UpdateWorkerModal';
import WorkBrightI9NeedsReview from './WorkBrightI9NeedsReview/WorkBrightI9NeedsReview';
import { ApolloError } from '@apollo/client';
import { BasicTag } from 'client/components/StatusTag/StatusTag';
import { FormikHelpers } from 'formik';
import { I9TaskTrackingEvents } from 'client/pages/i9/I9.tracking';
import { ProfileFormValues } from 'client/pages/profile/components/Form/formConstants';
import {
    UpdateWorkerValues,
    toUpdateWorkerInput,
} from 'client/pages/i9/WorkBrightI9/UpdateWorkerModal/formConstants';
import { WorkBrightI9Submitted } from './WorkBrightI9Submitted/WorkBrightI9Submitted';
import { assertNever } from 'client/utils/typescript';
import { datadogRum } from '@datadog/browser-rum';
import { getFormikErrors } from 'client/utils/graphql';
import { getStatusIncludesDuplicatedSsn } from 'client/components/UsSsnFields/UsSsnDuplicatedAlert/UsSsnDuplicatedAlert.utils';
import { indexLocation } from 'client/utils/redirect';
import { isReverifyRequired } from 'client/utils/task';
import { reportErrorToThirdPartyTools } from 'client/utils/errorReporting';
import {
    useGenerateWorkbrightI9RedirectUrlMutation,
    useUpdateWorkerMutation,
} from 'client/hooks/graphql';
import { useHistory } from 'react-router-dom';
import { useToast } from 'client/contexts/ToastContext';
import { useWorker } from 'client/contexts/WorkerContext';

// A subset of I9 TaskStatus that is applicable to I9 tasks
type I9TaskStatus = Extract<
    TaskStatus,
    'NOT_COMPLETED' | 'SUBMITTED' | 'NEEDS_REVIEW' | 'COMPLETED' | 'REJECTED' | 'APPROVED'
>;

type WorkBrightButtonProps = React.PropsWithChildren<{
    /**
     * The task's slug so that we can generate the appropriate workbright redirect url
     */
    slug: string;
    /**
     * The task's status for logging purposes
     */
    status: string;
    rejectionReason?: string | null;
}>;
const WorkBrightButton = ({
    children,
    slug,
    status,
    rejectionReason = null,
}: WorkBrightButtonProps): JSX.Element => {
    const [
        callGenerateWorkbrightI9RedirectUrlMutation,
        { loading: generatingUrl, error: apolloError },
    ] = useGenerateWorkbrightI9RedirectUrlMutation({
        variables: {
            input: {
                slug: slug,
            },
        },
    });

    // This is to designate a non apollo error (like the result being null)
    const [hasUnexpectedError, setHasUnexpectedError] = useState(false);
    const [isProcessing, setIsProcessing] = useState(false);
    // This is so we can avoid the redirect if the user navigates away from this component while the network request is running
    const mountedRef = useRef(true);
    useEffect(() => {
        mountedRef.current = true;
        return () => {
            mountedRef.current = false;
        };
    });

    const hasError = hasUnexpectedError || apolloError;

    const loading = isProcessing || generatingUrl;

    const { pushToast } = useToast();
    const { worker } = useWorker();
    const [openModal, setOpenModal] = useState(false);
    const [_, setSubmitting] = useState(false);
    const [callUpdateWorkerMutation] = useUpdateWorkerMutation();

    rejectionReason = rejectionReason ? rejectionReason.toLowerCase() : '';
    const needsDob = rejectionReason.includes('dob');
    const needsSsn = rejectionReason.includes('ssn');
    const needsName = rejectionReason.includes('name');

    const navigateToWorkBright = async (): Promise<void> => {
        // if there was a non apollo error we clear that on button click
        setHasUnexpectedError(false);
        try {
            setIsProcessing(true);
            const result = await callGenerateWorkbrightI9RedirectUrlMutation();
            // If they navigated away from this page while loading then do not attempt to redirect
            if (!mountedRef.current) {
                return;
            }
            const redirectUrl = result.data?.generateWorkbrightI9RedirectUrl?.redirectUrl;

            if (!redirectUrl) {
                // report the non apollo error and mark that an error occurred
                setHasUnexpectedError(true);
                reportErrorToThirdPartyTools(
                    `Missing redirectUrl from generate mutations, result errors: ${result.errors}`,
                    'WorkBrightButton',
                    'onClick'
                );
                setIsProcessing(false);
                return;
            }

            datadogRum.addAction(I9TaskTrackingEvents.redirectUrlClicked, {
                url: redirectUrl,
            });

            // When demoing in storybook I want to know that it would work without actually redirecting
            if (process.env.STORYBOOK) {
                alert(`Would redirect to ${redirectUrl}`);
                setIsProcessing(false);
            } else {
                window.location.href = redirectUrl;
            }
        } catch (error) {
            setIsProcessing(false);
            // I have the error handling occur here to ensure that the onClick handler does not get an uncaught error
            reportErrorToThirdPartyTools(error, 'WorkBrightButton', 'onClick');
        }
    };

    const onModalExit = (): void => {
        setOpenModal(false);
        setSubmitting(false);
    };
    const onSubmit = async (
        values: UpdateWorkerValues,
        formikHelpers: FormikHelpers<UpdateWorkerValues>
    ): Promise<void> => {
        setSubmitting(true);
        const input = toUpdateWorkerInput(worker!.accountKey!, values);
        try {
            const { data } = await callUpdateWorkerMutation({
                variables: { updateWorkerInput: input },
                refetchQueries: ['GetWorker'],
            });

            const result = data?.updateWorker?.result;
            if (result) {
                onModalExit();
                await navigateToWorkBright();
            } else {
                reportErrorToThirdPartyTools(
                    new Error(
                        `Could not retrieve result from update worker mutation given input ${JSON.stringify(
                            input
                        )}`
                    ),
                    'i9Page',
                    'onFormSubmit'
                );
                pushToast(
                    'There was an error while retrieving the response from submission, but the data may have saved. Refresh the page and try again.',
                    { status: 'warning' }
                );
            }
        } catch (err) {
            const updateWorkerApolloError = err as ApolloError;
            const formikErrors = getFormikErrors<ProfileFormValues>(updateWorkerApolloError);
            if (formikErrors) {
                // If SSN duplication set it in status which doesn't clear up on blur/change
                if (getStatusIncludesDuplicatedSsn(formikErrors)) {
                    formikHelpers.setStatus(formikErrors);
                    reportErrorToThirdPartyTools(
                        new Error('SSN duplicated'),
                        'i9Page',
                        'onFormSubmit'
                    );
                } else {
                    formikHelpers.setErrors(formikErrors);
                }
            } else {
                reportErrorToThirdPartyTools(apolloError, 'i9Page', 'onFormSubmit');
                pushToast(
                    'There was an error while attempting to submit the information, please try again.',
                    { status: 'critical' }
                );
            }
        }
    };

    return (
        <Box>
            {hasError && (
                <Alert status="critical" sx={{ marginBlockEnd: 5 }}>
                    An error has occurred accessing your Form I-9. Please try again later.
                </Alert>
            )}
            {status === 'NEEDS_REVIEW' ? (
                <>
                    <Button
                        onClick={
                            needsDob || needsSsn || needsName
                                ? () => {
                                      setOpenModal(true);
                                  }
                                : navigateToWorkBright
                        }
                    >
                        Review your Form I-9
                    </Button>
                    <UpdateWorkerModal
                        open={openModal}
                        setOpen={setOpenModal}
                        needsDob={needsDob}
                        needsSsn={needsSsn}
                        needsName={needsName}
                        onSubmit={onSubmit}
                    />
                </>
            ) : (
                <Button full onClick={navigateToWorkBright} disabled={loading} loading={loading}>
                    {children}
                </Button>
            )}
            <Flex
                sx={{ justifyContent: 'center', paddingBlockStart: 3 }}
                aria-label="Powered by Workbright"
                role="img"
            >
                <Heading level={1} sx={{ paddingRight: 2, color: '#191A5B' }} aria-hidden>
                    Powered by
                </Heading>
                {/* This is wrapped in a box so that the image scales down and maintains aspect ratio */}
                <Box sx={{ width: '6.75rem' }}>
                    <Image
                        alt="WorkBright Logo"
                        path="workbright-logo.png"
                        width="100%"
                        aria-hidden
                    />
                </Box>
            </Flex>
        </Box>
    );
};

type WorkBrightI9StatusProps = {
    /**
     * The status of the task to determine which UI to use
     */
    status: TaskStatus;
    externalStatus?: string | null;
};

/**
 * Return the right content for the given task status
 * @param options see type docs
 * @returns The content for this specific task
 */
const WorkBrightI9Status = ({ status, externalStatus }: WorkBrightI9StatusProps): JSX.Element => {
    // Utilizing this subset of TaskStatus to ensure that every expected case is handled and no extra cases are handled
    const i9TaskStatus = status as I9TaskStatus;
    switch (i9TaskStatus) {
        case 'SUBMITTED':
            return <WorkBrightI9Submitted />;
        case 'APPROVED':
        case 'COMPLETED':
            if (isReverifyRequired(externalStatus)) {
                return <WorkBrightI9Reverify />;
            }
            return <WorkBrightI9Complete />;
        case 'NOT_COMPLETED':
            return <WorkBrightI9NotComplete />;
        case 'NEEDS_REVIEW':
            return <WorkBrightI9NeedsReview />;
        case 'REJECTED':
            return <WorkBrightI9Rejected />;
        default:
            assertNever(i9TaskStatus);
            reportErrorToThirdPartyTools(
                new Error(`Unexpected task type "${status}"`),
                'WorkBrightI9Status',
                'onRender'
            );
    }
    return <WorkBrightI9ErrorPage />;
};

type WorkBrightI9ActionsProps = {
    /**
     * The status of the I9 task used to determine which button to use.
     */
    status: TaskStatus;
    /**
     * This is the slug on the task status to be passed to the workbright button.
     * The button uses this slug to get the redirect url
     */
    slug: string;
    /**
     * The reason the I9 was originally rejected. Used to display the modal to update Worker info
     * before resubmitting in Workbright
     */
    rejectionReason?: string | null;
    /**
     * The external status of the I9 task. Used to determine if the task needs to be reverified
     */
    externalStatus?: string | null;
};

/**
 * This function returns the appropriate actions based on the task's status
 * @param options see type docs
 * @returns The UI with the CTA for the jobseeker
 */
const WorkBrightI9Actions = ({
    status,
    slug,
    rejectionReason,
    externalStatus,
}: WorkBrightI9ActionsProps): JSX.Element => {
    const history = useHistory();
    // Utilizing this subset of TaskStatus to ensure that every expected case is handled and no extra cases are handled
    const i9TaskStatus = status as I9TaskStatus;

    switch (i9TaskStatus) {
        case 'SUBMITTED':
            return (
                <Button
                    full
                    onClick={() => {
                        datadogRum.addAction(I9TaskTrackingEvents.i9CtaClick, {
                            taskStatus: status,
                        });
                        history.push(indexLocation);
                    }}
                >
                    Return to Onboarding tasks
                </Button>
            );
        case 'APPROVED':
        case 'COMPLETED':
            if (isReverifyRequired(externalStatus)) {
                return (
                    <WorkBrightButton slug={slug} status={status}>
                        Reverify your Form I-9
                    </WorkBrightButton>
                );
            }
            return (
                <Button
                    full
                    onClick={() => {
                        datadogRum.addAction(I9TaskTrackingEvents.i9CtaClick, {
                            taskStatus: status,
                        });
                        history.push(indexLocation);
                    }}
                >
                    Complete your Onboarding tasks
                </Button>
            );
        case 'NOT_COMPLETED':
            return (
                <WorkBrightButton slug={slug} status={status}>
                    Submit your Form I-9
                </WorkBrightButton>
            );
        case 'NEEDS_REVIEW':
            return (
                <WorkBrightButton slug={slug} status={status} rejectionReason={rejectionReason}>
                    Review your Form I-9
                </WorkBrightButton>
            );
        case 'REJECTED':
            // No buttons on rejected page even though there is a page
            break;
        default:
            assertNever(i9TaskStatus);
            reportErrorToThirdPartyTools(
                new Error(`Invalid task type "${status}"`),
                'WorkBrightI9Actions',
                'onRender'
            );
    }
    // Return UI for unexpected status or invalid status
    // There is a contact support bubble in the figma but it is not part of MVP
    return <></>;
};

type WorkBrightI9ContentProps = WorkBrightI9StatusProps &
    WorkBrightButtonProps &
    WorkBrightI9ActionsProps;
/**
 * Workbright I9 UI
 * @param options see type docs
 * @returns UI for I9 using workbright vendor
 */
export const WorkBrightI9Content = ({
    status,
    slug,
    rejectionReason = null,
    externalStatus,
}: WorkBrightI9ContentProps): JSX.Element => {
    return (
        <Box>
            {/* The eslint isn't applicable here because this is how styled components work */}
            {/* eslint-disable-next-line @typescript-eslint/naming-convention */}
            <Card sx={{ '> div': { p: 0 } }}>
                <Box sx={{ paddingBlockStart: 5, paddingInlineStart: 5, paddingInlineEnd: 5 }}>
                    {/* This title already exists from A11yPageTitle so we're hiding the duplicate heading */}
                    <Heading aria-hidden>Form I-9</Heading>
                    <BasicTag taskStatus={status} externalStatus={externalStatus} />
                </Box>
                <WorkBrightI9Status status={status} externalStatus={externalStatus} />
            </Card>
            <Box sx={{ marginBlockStart: '1rem' }}>
                <WorkBrightI9Actions
                    status={status}
                    slug={slug}
                    rejectionReason={rejectionReason}
                    externalStatus={externalStatus}
                />
            </Box>
        </Box>
    );
};

export default WorkBrightI9Content;
