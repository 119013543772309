import React from 'react';
import { CareersOrCandidates5 } from '@indeed/ipl-illustrations-spot';
import { DatadogAction } from 'client/utils/logging';
import {
    GraphicContentSection,
    TextContentSection,
} from 'client/pages/i9/WorkBrightI9/ContentSection';
import { LogRenderDatadogAction } from 'client/components/LogRenderDatadogAction';

const WorkBrightI9Reverify = (): JSX.Element => {
    const reverifyDetails = (
        <ul>
            <li>Your Work Authorization or receipt document is expiring</li>
            <li>You have requested a legal name change on your account</li>
            <li>You are being rehired by Indeed Flex</li>
        </ul>
    );
    return (
        <LogRenderDatadogAction action={DatadogAction.i9ReverifyRendered}>
            <GraphicContentSection>
                <CareersOrCandidates5 sx={{ display: 'flex' }} />
            </GraphicContentSection>
            <TextContentSection
                heading="You have been notified by Indeed Flex to complete the reverification process for your I-9. This means one of the following apply to you:"
                text={reverifyDetails}
                omitBottomPadding={true}
            />
            <TextContentSection
                summaryHeading="Important to know:"
                text="Make sure you have your documentation on hand and have designated an authorized representative to sign off on supplement B/Section 3. It can be anyone over 18 and they must be in the same location as you."
            />
        </LogRenderDatadogAction>
    );
};
export default WorkBrightI9Reverify;
