import isEmpty from 'lodash/isEmpty';
import { BgcPackage } from './graphql';
import { StatusTypes, TaskAndPlacementStatusCounts } from './placements';

export const TaskStatuses: Record<TaskStatus, TaskStatus> = {
    NOT_COMPLETED: 'NOT_COMPLETED',
    SUBMITTED: 'SUBMITTED',
    NEEDS_REVIEW: 'NEEDS_REVIEW',
    REJECTED: 'REJECTED',
    COMPLETED: 'COMPLETED',
    APPROVED: 'APPROVED',
    FLEX_APPROVED: 'FLEX_APPROVED',
    EXPIRING: 'EXPIRING',
    EXPIRED: 'EXPIRED',
    DISABLED: 'DISABLED',
} as const;

export const ProgressStatuses: Record<ProgressStatus, ProgressStatus> = {
    NOT_STARTED: 'NOT_STARTED',
    NEEDS_WORKER_ACTION: 'NEEDS_WORKER_ACTION',
    NEEDS_AGENT_ACTION: 'NEEDS_AGENT_ACTION',
    NEEDS_VENDOR_ACTION: 'NEEDS_VENDOR_ACTION',
    REJECTED: 'REJECTED',
    APPROVED: 'APPROVED',
    EXPIRING: 'EXPIRING',
    EXPIRED: 'EXPIRED',
} as const;

export const enum BackgroundCheckStatuses {
    FAIL = 'Fail',
    REVIEW = 'Review',
}

/**
 * Return true if `metadataKey` field exists on `metadata` field
 * Wrapped in helper function because typescript unions require this kind of check
 */
const isTaskWithMetadataKey = (
    task: ComplianceTask | undefined | null,
    metadataKey: string
): boolean => {
    return !!task?.metadata && metadataKey in task.metadata;
};

export const isBackgroundCheckTask = (task: ComplianceTask | undefined | null): boolean => {
    return isTaskWithMetadataKey(task, 'backgroundCheck');
};

export const isProfileTask = (task: ComplianceTask | undefined | null): boolean => {
    return task?.type === 'PROFILE';
};

export const isConditionalJobOfferTask = (task: ComplianceTask | undefined | null): boolean => {
    return task?.type === 'CONDITIONAL_JOB_OFFER';
};

export const isRightToWorkTask = (
    task: ComplianceTask | ReverifiableComplianceTask | undefined | null
): boolean => {
    // in some cases we don't ask for the `rightToWork` field, but usually it's safer to check
    return task?.type === 'RIGHT_TO_WORK' || isTaskWithMetadataKey(task, 'rightToWork');
};

export const isI9Task = (task: ComplianceTask | undefined | null): boolean => {
    return isTaskWithMetadataKey(task, 'formI9');
};

export const isPayrollTask = (task: ComplianceTask | undefined | null): boolean => {
    return task?.type === 'PAYROLL';
};

export const isFederalTaxTask = (task: ComplianceTask | undefined | null): boolean => {
    return task?.type === 'FEDERAL_TAX_ELECTIONS';
};

export const isUkPayrollTask = (task: ComplianceTask | undefined | null): boolean => {
    return task?.type === 'UK_PAYROLL';
};

export const isLocationRequirementsTask = (task: ComplianceTask | undefined | null): boolean => {
    return task?.type === 'LOCATION_REQUIREMENTS';
};

export const isMediaRequirementsTask = (task: ComplianceTask | undefined | null): boolean => {
    return task?.type === 'MEDIA_REQUIREMENTS';
};

export const isEmergencyContactTask = (task: ComplianceTask | undefined | null): boolean =>
    task?.type === 'EMERGENCY_CONTACT';

export const isUkDocumentsTask = (task: ComplianceTask | undefined | null): boolean =>
    task?.type === 'UK_DOCUMENTS';

export const isBankDetailsTask = (task: ComplianceTask | undefined | null): boolean =>
    task?.type === 'BANK_DETAILS';

export const isOnboardedTask = (task: ComplianceTask | undefined | null): boolean =>
    task?.type === 'ONBOARDED';

export const findTaskByType = (
    tasks: ComplianceTask[],
    taskType: TaskType
): ComplianceTask | undefined => {
    return tasks.find((task) => task.type === taskType);
};

export const filterByTaskType = (tasks: ComplianceTask[], taskType: TaskType): ComplianceTask[] => {
    return tasks.filter((task) => task.type === taskType);
};

/**
 * Return background check metadata if it exists on task
 * Wrapped in helper function because typescript unions require this kind of check
 * and accessing the field on `metadata` requires a ts-ignore which would be annoying to add everywhere
 */
export const getBackgroundCheckMetadata = (
    task: ComplianceTask | null
): ComplianceTaskBackgroundCheckType | null => {
    // @ts-ignore: background check is on metadata if in true ternary branch
    return isBackgroundCheckTask(task) ? task.metadata.backgroundCheck : null;
};

export const getMediaRequirementsMetadata = (
    task: ComplianceTask | null
): MediaRequirementsType | null => {
    // @ts-ignore: media requirements is on metadata if in true ternary branch
    return isMediaRequirementsTask(task) ? task.metadata.mediaRequirements : null;
};

export const getLocationRequirementsMetadata = (
    task: ComplianceTask | null
): LocationRequirementsType | null => {
    // @ts-ignore: location requirements is on metadata if in true ternary branch
    return isLocationRequirementsTask(task) ? task.metadata.locationRequirements : null;
};

export const getEmergencyContactMetadata = (
    task: ComplianceTask | null
): EmergencyContactType | null => {
    // @ts-ignore: emergency contact is on metadata if in true ternary branch
    return isEmergencyContactTask(task) ? task.metadata.emergencyContact : null;
};

export const getUkDocumentsMetadata = (task: ComplianceTask | null): UkDocumentsType | null => {
    // @ts-ignore: uk documents is on metadata if in true ternary branch
    return isUkDocumentsTask(task) ? task.metadata.ukDocuments : null;
};

export const getTaskLocationLabel = (task: ComplianceTask): string => {
    if (!isMediaRequirementsTask(task) && !isLocationRequirementsTask(task)) {
        return '';
    }
    const location = isLocationRequirementsTask(task)
        ? getLocationRequirementsMetadata(task)?.location
        : getMediaRequirementsMetadata(task)?.location;
    return location ? (location.city ? `${location.city}, ` : '') + `${location.state}` : '';
};

export const getTaskTitle = (
    task: ComplianceTask,
    includeLocation: boolean = false,
    agentAction: boolean = false
): string => {
    switch (task.type) {
        case 'BACKGROUND_CHECK':
            const metadata = getBackgroundCheckMetadata(task)!;
            const bgcPackageTitle =
                // Ready2Work + County (id: 2117) is the standard bgc but label is only used when agents create
                // new BGC task
                metadata.package === BgcPackage.READY2WORK_COUNTY
                    ? agentAction
                        ? 'Flex Standard'
                        : ''
                    : metadata.title;
            return 'Background check' + (bgcPackageTitle ? ` - ${bgcPackageTitle}` : '');
        case 'CONDITIONAL_JOB_OFFER':
            return 'Conditional job offer';
        case 'FORM_I9':
            return 'Form I-9';
        case 'PAYROLL':
            return 'Pay';
        case 'PROFILE':
            return 'Profile';
        case 'LOCATION_REQUIREMENTS':
            const taskLocation = getTaskLocationLabel(task);
            return (
                'Additional documents' +
                (taskLocation && includeLocation ? ` - ${taskLocation}` : '')
            );
        case 'MEDIA_REQUIREMENTS':
            const mediaLocation = getTaskLocationLabel(task);
            return (
                'Additional trainings' +
                (mediaLocation && includeLocation ? ` - ${mediaLocation}` : '')
            );
        case 'RIGHT_TO_WORK':
            return 'Right to work';
        case 'EMERGENCY_CONTACT':
            return 'Emergency contact';
        case 'UK_PAYROLL':
            return 'Pay';
        case 'UK_DOCUMENTS':
            return 'Onboarding documents';
        case 'BANK_DETAILS':
            return 'Bank details';
        case 'FEDERAL_TAX_ELECTIONS':
            return 'Federal tax elections';
        case 'STATE_TAX_ELECTIONS':
            return 'State tax elections';
        case 'ONBOARDED':
            return task.metadata?.name ?? 'Onboarded task';
        default:
            return '';
    }
};

export const getConditionalJobOfferMetadata = (
    task: ComplianceTask | null
): ConditionalJobOfferMetadataType | null => {
    // @ts-ignore: conditional job offer is on metadata if in true ternary branch
    return isConditionalJobOfferTask(task) ? task.metadata.conditionalJobOffer : null;
};

/**
 * Return background check metadata if it exists on task
 * Wrapped in helper function because typescript unions require this kind of check
 * and accessing the field on `metadata` requires a ts-ignore which would be annoying to add everywhere
 */
export const getRightToWorkMetadata = (
    task: ComplianceTask | ReverifiableComplianceTask | null
): RightToWorkType | null => {
    return isRightToWorkTask(task) ? task.metadata?.rightToWork : null;
};

export const getRightToWorkWorker = (
    task: ComplianceTask | ReverifiableComplianceTask | null
): RightToWorkWorkerType | null => {
    // @ts-ignore: worker is on metadata if in true ternary branch
    return isRightToWorkTask(task) ? task.metadata.worker : null;
};

export const getRightToWorkWorkerGovernmentIds = (
    task: ComplianceTask | null | undefined
): RightToWorkWorkerGovernmentIdType[] | null => {
    // @ts-ignore: workerGovernmentIds is on metadata if in true ternary branch
    return isRightToWorkTask(task) ? task.metadata.workerGovernmentIds : null;
};

export const getI9Metadata = (task: ComplianceTask | null): FormI9Type | null => {
    // @ts-ignore: right to work is on metadata if in true ternary branch
    return isI9Task(task) ? task.metadata.formI9 : null;
};

export const getTaskDocuments = (task: ComplianceTask | null): ComplianceTaskWithDocumentsType => {
    if (
        !isBackgroundCheckTask(task) &&
        !isConditionalJobOfferTask(task) &&
        !isLocationRequirementsTask(task) &&
        !isMediaRequirementsTask(task) &&
        !isUkDocumentsTask(task)
    ) {
        throw new Error(`Attempted to get documents from task without documents ${task?.slug}`);
    }
    return task!.metadata as ComplianceTaskWithDocumentsType;
};

export const isLegacyCjo = (task: ComplianceTask): boolean =>
    task.type === 'CONDITIONAL_JOB_OFFER' &&
    getTaskDocuments(task).workerDocuments.length === 0 &&
    task.status === 'COMPLETED';

export const hasTaskDocuments = (task: ComplianceTask): boolean =>
    isBackgroundCheckTask(task) ||
    (isConditionalJobOfferTask(task) && !isLegacyCjo(task)) ||
    isLocationRequirementsTask(task) ||
    isMediaRequirementsTask(task) ||
    isUkDocumentsTask(task);

export const getMatchingWorkerDocument = (
    template: TaskDocumentTemplateType,
    workerDocuments: TaskWorkerDocumentType[]
): TaskWorkerDocumentType | null => {
    return (
        workerDocuments.find((workerDocument) => workerDocument.templateType === template.type) ||
        null
    );
};

export const getTaskWithNextDocuments = (
    tasks: ComplianceTask[],
    currentTask: ComplianceTask
): ComplianceTask | null => {
    return (
        tasks
            .filter((task) => task.slug !== currentTask.slug)
            .find((task) => {
                const { templates, workerDocuments } = getTaskDocuments(task);
                return templates.find(
                    (template) => !getMatchingWorkerDocument(template, workerDocuments)
                );
            }) || null
    );
};

/**
 * Returns if a task is enabled
 */
export const isTaskEnabled = (task: ComplianceTask): boolean => {
    switch (task.type) {
        case 'PAYROLL':
        case 'PROFILE':
        case 'FORM_I9':
        case 'EMERGENCY_CONTACT':
        case 'UK_PAYROLL':
        case 'UK_DOCUMENTS':
        case 'ONBOARDED':
            return true;
        case 'BACKGROUND_CHECK':
            return getBackgroundCheckMetadata(task)?.provider !== 'TRANSFER';
        default:
            return true;
    }
};

// @ts-ignore: it's not smart enough to tell that these strings will become keys
// so it's complaining that we don't have all the values in the record
export const complianceTaskOrder: Record<TaskType, number> = [
    'PROFILE',
    'CONDITIONAL_JOB_OFFER',
    'LOCATION_REQUIREMENTS',
    'FORM_I9',
    'BACKGROUND_CHECK',
    'RIGHT_TO_WORK',
    'PAYROLL',
    'DRUG_TEST',
    'MEDIA_REQUIREMENTS',
    'UK_DOCUMENTS',
    'EMERGENCY_CONTACT',
    'UK_PAYROLL',
].reduce((acc, curr, idx) => ({ ...acc, [curr]: idx }), {});

export const byTaskType = (a: ComplianceTask, b: ComplianceTask): number => {
    const aType = a.type;
    const bType = b.type;
    return complianceTaskOrder[aType] - complianceTaskOrder[bType];
};

export const byTaskLocation = (a: ComplianceTask, b: ComplianceTask): number => {
    const isMediaTask = isMediaRequirementsTask(a);
    const aMetadata = isMediaTask
        ? getMediaRequirementsMetadata(a)
        : getLocationRequirementsMetadata(a);
    const bMetadata = isMediaTask
        ? getMediaRequirementsMetadata(b)
        : getLocationRequirementsMetadata(b);
    const aState = aMetadata!!.location!!.state as String;
    const bState = bMetadata!!.location!!.state as String;
    const aCity = aMetadata!!.location!!.city as String;
    const bCity = bMetadata!!.location!!.city as String;

    if (aState === bState) {
        if (!aCity && bCity) return -1;
        else if (aCity && !bCity) return 1;
        else if (!aCity && !bCity) return 0;
        else if (aCity!! < bCity!!) return -1;
        else if (aCity!! > bCity!!) return 1;
        else return 0;
    } else {
        if (!aState && bState) return -1;
        else if (aState && !bState) return 1;
        else if (!aState && !bState) return 0;
        else if (aState!! < bState!!) return -1;
        else if (aState!! > bState!!) return 1;
        else return 0;
    }
};

/**
 * If this list gets any bigger, this should likely be controlled through a field on the template in the db
 */
export const toggleableTemplateTypes = [
    'CA_FIRST_MEAL_PERIOD_WAIVER',
    'CA_SECOND_MEAL_PERIOD_WAIVER',
];

/**
 * Returns true if a worker should be able to sign or decline the given type of document at any time.
 */
export const isDocumentToggleable = (documentTemplate: TaskDocumentTemplateType): boolean =>
    documentTemplate.requirement === 'SIGN_OR_DECLINE' &&
    toggleableTemplateTypes.includes(documentTemplate.type ?? '');

/*
 * Check if based on the task status the worker's credas verification has passed or not.
 * Currently it just checks whether the task has any government IDs attached
 */
export const hasWorkerPassedCredas = (taskStatus: TaskStatus): boolean => {
    return !(
        taskStatus === 'NEEDS_REVIEW' ||
        taskStatus === 'NOT_COMPLETED' ||
        taskStatus === 'SUBMITTED'
    );
};

export const hasValidSharecode = (task: ComplianceTask): boolean => {
    // maybe take something besides the Compliance Task
    return (
        !isEmpty(task.metadata.rightToWork.shareCode) &&
        !task.metadata.rightToWork.ukCitizen &&
        task.status !== 'NOT_COMPLETED'
    );
};

export const hasRtwTask = (tasks: ComplianceTask[]): boolean => {
    return tasks.some((task) => task.type === 'RIGHT_TO_WORK');
};

export const mapStatusCounts = (
    statusCounts?: TaskAndPlacementStatusCounts | null
): Record<StatusTypes, number> | null => {
    if (!statusCounts) {
        return null;
    }
    return statusCounts.reduce(
        (acc: Record<StatusTypes, number>, { status, count }) => ({
            ...acc,
            [status]: count,
        }),
        {} as Record<StatusTypes, number>
    );
};

/**
 * Look up a worker's task by slug among its activeTasks and activePlacements
 * @param slug task slug
 * @param worker Worker returned in GetWorkerWithTasksAndPlacementsQuery
 * @returns
 */
export const findTaskInWorkerTasksOrPlacements = (
    slug: string,
    worker: ComplianceWorker
): ComplianceTask | null | undefined => {
    if (!slug || !worker) {
        return null;
    }
    const activeTasks = worker.activeTasks ?? [];
    const placementTasks = worker?.activePlacements
        ? (worker.activePlacements as Placement[]).flatMap(
              (placement) => placement.activeTasks ?? []
          )
        : [];

    const allTasks = [...activeTasks, ...placementTasks];

    return allTasks.find((task) => task?.slug === slug) as ComplianceTask;
};

export const isReverifyRequired = (externalStatus: string | null | undefined): boolean => {
    return externalStatus === 'reverify_required';
};

export const isTaskInactive = (task: ComplianceTask): boolean => {
    return task.active === false;
};

export const isRtwTaskNotStarted = (task: ComplianceTask): boolean => {
    const isTaskEmpty = task.progressStatus === ProgressStatuses.NOT_STARTED;

    return isRightToWorkTask(task) && isTaskEmpty;
};
