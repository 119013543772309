import React from 'react';
import { Box, Heading, Text } from '@indeed/ifl-components';
import { IndeedTheme } from '@indeed/ifl-themes';

type ContentSectionProps = React.PropsWithChildren<{
    /**
     * This removes the bottom padding on the section.
     * This is needed for the section that contains the graphic
     * since the figma has the graphic hug the border.
     * Defaults to false
     */
    omitBottomPadding?: boolean;
    /**
     * This removes the bottom border.
     * This needs to be set to true for all sections that are not on the bottom.
     * This is used on the bottom section so that there are not two borders on top of each other.
     * For ContentSection this defaults to false but for TextContentSection this defaults to true.
     */
    omitBottomBorder?: boolean;
}>;

/**
 * This is the base container for sections within the workbright pages.
 * The reason this container is needed is because there are separators between the sections but there is also consistent padding.
 * Since the divider needs to span the full section we can't put the padding on the parent container and instead need it repeated.
 * This also includes props for common modifications to this container.
 * @param props see type docs
 * @returns A component with common styling for each section
 */
export const ContentSection = ({
    omitBottomBorder = false,
    omitBottomPadding = false,
    children,
}: ContentSectionProps): JSX.Element => {
    return (
        <Box
            sx={{
                borderBottom: omitBottomBorder
                    ? undefined
                    : ({ colors }: IndeedTheme) => `1px solid ${colors.neutral['400']}`,
                padding: 5,
                paddingBlockEnd: omitBottomPadding ? 0 : undefined,
            }}
        >
            {children}
        </Box>
    );
};

type TextContentSectionProps = Pick<
    ContentSectionProps,
    'omitBottomBorder' | 'omitBottomPadding'
> & {
    /**
     * This is a common first heading that is included in most sections.
     * This could be text like: "What's next:" or "Important to know:".
     * This string defaults to "What's next:" but can be omitted with `omitSummaryHeading` prop.
     */
    summaryHeading?: string;
    /**
     * This is a heading that most components have with a brief description of the task.
     * It is extremely rare but there is a case when a heading is not included,
     * if it is not included it will not render the heading component
     */
    heading?: string;
    /**
     * These are the main details in the section
     */
    text: string | JSX.Element;
    /**
     * Whether or not `summaryHeading` should be rendered.
     * Defaults to false
     */
    omitSummaryHeading?: boolean;
};
/**
 * This is common UI used for workbright sections that contain the details of the task at hand.
 * This UI includes (with a default value that can be omitted) a top level heading
 * Then this UI includes (but is optional) a secondary brief description of the task
 * And lastly this UI includes text as a description for the text
 * @param options see type docs
 * @returns The UI for text based workbright sections
 */
export const TextContentSection = ({
    summaryHeading = "What's next:",
    heading,
    text,
    omitSummaryHeading = false,
    omitBottomBorder = true,
    omitBottomPadding = false,
}: TextContentSectionProps): JSX.Element => {
    return (
        <ContentSection omitBottomBorder={omitBottomBorder} omitBottomPadding={omitBottomPadding}>
            {!omitSummaryHeading && (
                <Heading level={2} sx={{ color: 'neutral.800', marginBlockEnd: 4 }}>
                    {summaryHeading}
                </Heading>
            )}
            {heading && (
                <Heading level={3} sx={{ marginBlockEnd: 3 }}>
                    {heading}
                </Heading>
            )}
            <Text level={3}>{text}</Text>
        </ContentSection>
    );
};

/**
 * This is a container section for the graphic included with each workbright page.
 * This component does not actually include the graphic but rather sets up the container properly (graphic is sent in children)
 * @param options React props that only include children
 * @returns A container to house graphics
 */
export const GraphicContentSection = ({ children }: React.PropsWithChildren<{}>): JSX.Element => {
    return (
        <ContentSection omitBottomPadding>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                }}
            >
                {children}
            </Box>
        </ContentSection>
    );
};
